import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['workoutSession']

  nextWorkoutSession(e) {
    e.preventDefault()
    var currentIndex = this.workoutSessionTargets.findIndex((element) => !element.classList.contains("hidden"))
    var nextIndex = currentIndex == (this.workoutSessionTargets.length - 1) ? 0 : (currentIndex + 1)

    this.workoutSessionTargets[currentIndex].classList.add("hidden")
    this.workoutSessionTargets[nextIndex].classList.remove("hidden")
  }

  previousWorkoutSession(e) {
    e.preventDefault()
    var currentIndex = this.workoutSessionTargets.findIndex((element) => !element.classList.contains("hidden"))
    var nextIndex = currentIndex == 0 ? (this.workoutSessionTargets.length - 1) : (currentIndex - 1)

    this.workoutSessionTargets[currentIndex].classList.add("hidden")
    this.workoutSessionTargets[nextIndex].classList.remove("hidden")
  }
}
