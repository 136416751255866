import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['modal']

  hide(e) {
    e.preventDefault()
    this.modalTarget.classList.add("hidden")
  }

  show(e) {
    e.preventDefault()
    this.modalTarget.classList.remove("hidden")
  }
}