import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["genderInput", "activeInjury", "activityInput"]
  static values = {"gender": String, "activeInjury": String}

  connect() {
    this.genderToggle(this.genderValue)
    this.activeInjuryToggle(this.activeInjuryValue)
  }

  enableDisableOtherGender(e)  {
    this.genderToggle(e.target.value)
  }

  genderToggle(currentGenderValue) {
    if(currentGenderValue === "additional") {
      this.genderInputTarget.removeAttribute("disabled")
    } else {
      this.genderInputTarget.value = ""
      this.genderInputTarget.setAttribute("disabled", true)
    }
  }

  activeInjuryToggle(currentInjuryValue) {
    if(currentInjuryValue === "true") {
      this.activeInjuryTarget.removeAttribute("hidden")
    } else {
      this.activityInputTarget.value = ""
      this.activeInjuryTarget.setAttribute("hidden", true)
    }
  }

  showHideActiveInjury(e) {
    this.activeInjuryToggle(e.target.value)
  }
}
