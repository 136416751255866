import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['radio']
  static values = {'active': String}

  connect() {
    this.toggleHighlight()
  }

  toggleHighlight() {
    var closestInfo = this.activeValue
    this.radioTargets.forEach((target) =>  {
      if (target.checked) {
        this.highlightSelected(target, closestInfo)
      } else {
        this.unhighlightSelected(target, closestInfo)
      }
    })
  }

  highlightSelected(target, closestInfo) {
    target.closest(closestInfo).classList.add('selected')
  }

  unhighlightSelected(target, closestInfo) {
    target.closest(closestInfo).classList.remove('selected')
  }
}
