import { Controller } from 'stimulus';
import {enter, leave} from 'el-transition';
export default class extends Controller {
  static targets = ['mobileNav', 'hamburgerIcon', 'closeIcon']

  toggle(e) {
    e.preventDefault()
    if(this.mobileNavTarget.classList.contains("hidden")) {
      this.hamburgerIconTarget.classList.add("hidden")
      this.closeIconTarget.classList.remove("hidden")
      enter(this.mobileNavTarget)
    } else {
      this.hamburgerIconTarget.classList.remove("hidden")
      this.closeIconTarget.classList.add("hidden")
      leave(this.mobileNavTarget)
    }
  }
}