import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['level']
  static values = {
    activeLevel: Number,
  }

  initialize() {
    setTimeout(() => {
      this.togglePanelVisibility(this.levelTargets[this.activeLevelValue]);
    }, 500);
  }

  openPanel(e) {
    this.togglePanelVisibility(e.currentTarget.parentElement);
  }

  togglePanelVisibility(panel) {
    panel.classList.toggle("closed");
  }
}
