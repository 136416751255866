import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ['another', 'template', 'nestedField']
  static values = {'outer': String}

  enableDisableNestedForm(e) {
    if(e.target.checked) {
      this.addAnother(e)
    } else {
      this.nestedFieldTargets.forEach((el) => el.remove())
    }
  }

  addAnother(e) {
    e.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.anotherTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove(target) {
    target.querySelector("input[name*='_destroy']").value = 1
    target.classList.add('hidden')
  }

  removeMotion(e) {
    e.preventDefault()
    var nestedFields = this.outerValue
    var closestMotion = e.target.closest(nestedFields)
    if (closestMotion.dataset.newRecord === 'true') {
      closestMotion.remove()
    } else {
      this.remove(closestMotion)
    }
  }
}
