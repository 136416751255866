import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["motionRestriction", "motionInput", "weightBearingRestriction", "weightInput", "bracingRestriction", "braceInput"]
  static values = { motion: Boolean, weight: Boolean, brace: Boolean }

  connect() {
    this.restrictionToggle(this.motionValue, this.motionRestrictionTarget, this.motionInputTargets)
    this.restrictionToggle(this.weightValue, this.weightBearingRestrictionTarget, this.weightInputTargets)
    this.restrictionToggle(this.braceValue, this.bracingRestrictionTarget, this.braceInputTargets)
  }

  showHideMotionRestriction(e) {
    this.restrictionToggle(e.target.checked, this.motionRestrictionTarget, this.motionInputTargets)
  }

  showHideWeightBearingRestriction(e) {
    this.restrictionToggle(e.target.checked, this.weightBearingRestrictionTarget, this.weightInputTargets)
  }

  showHideBracingRestriction(e) {
    this.restrictionToggle(e.target.checked, this.bracingRestrictionTarget, this.braceInputTargets)
  }

  restrictionToggle(currentMotionRestrictionValue, divTarget, inputTargets) {
    if(currentMotionRestrictionValue === true) {
      this.showDiv(divTarget)
    } else if(currentMotionRestrictionValue === false) {
      this.clearInputs(inputTargets)
      this.hideDiv(divTarget)
    }
  }

  showDiv(target) {
    target.removeAttribute("hidden")
  }

  hideDiv(target) {
    target.setAttribute("hidden", true)
  }

  clearInputs(targets) {
    targets.forEach((input) => {
        input.value = ""
      } )
  }
}
