import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.element.classList.remove('opacity-100');
      this.element.classList.add('opacity-0');
    }, 5000)
    setTimeout(() => { this.element.classList.add('hidden') }, 5500);
  }

  dismiss() {
    this.element.classList.remove('opacity-100');
    this.element.classList.add('opacity-0');
    setTimeout(() => { this.element.classList.add('hidden') }, 500);
  }
}