import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["kneeSurgery", "kneeInjury", "kneeInjuryInput", "kneeSurgeryInput", "rangeOfMotion"]
  static values = {"kneeSurgery": String}

  connect() {
    this.kneeSurgeryToggle(this.kneeSurgeryValue)
  }

  kneeSurgeryToggle(currentKneeSurgeryValue) {
    if(currentKneeSurgeryValue === "true") {
      this.clearInputs(this.kneeInjuryInputTargets)
      this.showDiv(this.kneeSurgeryTarget)
      this.showDiv(this.rangeOfMotionTarget)
      this.hideDiv(this.kneeInjuryTarget)
    } else if(currentKneeSurgeryValue === "false") {
      this.clearInputs(this.kneeSurgeryInputTargets)
      this.showDiv(this.kneeInjuryTarget)
      this.showDiv(this.rangeOfMotionTarget)
      this.hideDiv(this.kneeSurgeryTarget)
    }
  }

  showDiv(target) {
    target.removeAttribute("hidden")
  }

  hideDiv(target) {
    target.setAttribute("hidden", true)
  }

  clearInputs(targets) {
    targets.forEach((input) => {
        input.value = ""
      } )
  }

  showHideKneeSurgery(e) {
    this.kneeSurgeryToggle(e.target.value)
  }
}
