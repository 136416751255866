import { Controller } from 'stimulus';
export default class extends Controller {
  static targets = ["injured", "nonInjured", "baseOutput", "injuryOutput"]

  fillMeasurements() {
    var highestInjured = this.highest(this.injuredTargets)
    var highestNonInjured = this.highest(this.nonInjuredTargets)
    this.baseOutputTarget.value = highestNonInjured
    this.injuryOutputTarget.value = highestInjured
  }

  highest(targets) {
    var numbers = this.convertToNum(targets)
    return Math.max(...numbers)
  }

  convertToNum(targets) {
    return targets.map(x => Number(x.value))
  }
}
